<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Условия доставки</v-toolbar-title>
    </v-toolbar>
    <v-row class="mx-5 mt-1">
      <v-col cols="10"></v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded @click="create"
               color="primary">
          <span class="font-weight-bold hidden-xs-only">Создать</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'mx-3' : 'ml-3'" class="justify-space-between" v-for="item in list" :key="item.id">
      <v-col cols="12" class="col col-lg-3 col-md-3 col-sm-3">
        <v-text-field v-model="item.name"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Название"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.min_distance"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Мин. дистанция"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.max_distance"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Макс. дистанция"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.min_price"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Мин.цена заказа"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.price"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Цена доставки"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.commission"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Коммиссия"
                      @input="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
        <v-text-field v-model="item.delivery_price"
                      dense
                      outlined
                      rounded
                      hide-details
                      label="Итого"
                      :readonly="true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''" class="align-center d-flex" :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
        <v-checkbox v-model="item.partner"
                    class="ma-0 pa-0"
                    hide-details
                    label="Партнер"
                    @change="item.save = true"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? '6' : '1'" class="row align-center d-flex" :class="$vuetify.breakpoint.xs ? 'no-gutters justify-center' : ''">
        <v-btn icon @click="addRule(item)" v-if="item.save" color="primary">
          <v-icon>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn icon @click="removeRule(item)" v-else color="error">
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import axios from "../../services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      list: []
    }
  },

  beforeMount() {
    this.fetch();
  },

  methods: {
    fetch() {
      axios.send({
        url: "/rules",
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      })
    },

    create() {
      let item = {
        id: uuid.Nil,
        name: "",
        min_distance: 0,
        max_distance: 0,
        delivery_price: 0,
        price: 0,
        commission: 0,
        save: true,
        partner: false
      }
      this.list.unshift(item);
    },

    addRule(item) {
      let data = {
        id: item.id,
        name: item.name,
        min_distance: parseFloat(item.min_distance),
        max_distance: parseFloat(item.max_distance),
        price: parseInt(item.price),
        commission: parseInt(item.commission),
        partner: item.partner
      }
      if (item.min_price) {
        data.min_price = parseInt(item.min_price)
      }
      axios({
        url: "/rules",
        method: "POST",
        data: data
      }).then(() => {
        this.fetch();
      })
    },
    removeRule(item) {
      let data = {
        id: item.id
      }
      axios({
        url: "/rules",
        method: "DELETE",
        data: data
      }).then(() => {
        this.fetch();
      })
    }
  }
}
</script>
